import React, { useEffect, useState } from "react"
import { Box, Text } from "@chakra-ui/core"
import Image from "gatsby-image"
import styled from "styled-components"

export default function GridItem({ imageData, ...props }) {
  const [hover, setHover] = useState(false)
  const [downloaded, setDownloaded] = useState(false)

  const download = Fileurl => {
    let url = window.location.origin + Fileurl.src
    let filenameArray = Fileurl.src.split("/")
    let a = document.createElement("a")
    let filename = filenameArray[filenameArray.length - 1]
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setDownloaded(true)
    setTimeout(() => setDownloaded(false), 3000)
  }

  return (
    <Box
      pos="relative"
      h="fit-content"
      borderRadius="5px"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover ? (
        <Box
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          pos={"absolute"}
          w="100%"
          bg="rgb(0, 0, 0, 0.8)"
          zIndex="1000"
          h="100%"
          borderRadius="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => download(imageData)}
        >
          <Text color="white" display="flex" mr="10px">
            {downloaded ? "Downloaded 😃" : "Click to Download"}
          </Text>
          {!downloaded ? (
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ color: "white" }}
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <GridImage loading="lazy" fluid={imageData} {...props} />
    </Box>
  )
}

const GridImage = styled(Image)`
  border: 5px solid #1f364d;
  border-radius: 5px;
`
