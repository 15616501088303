import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SimpleGrid } from "@chakra-ui/core"
import GridItem from "../components/GridItem"

const BlogIndex = ({ data, location }) => {
  const images = data.allFile.edges[0].node.childrenImagesJson
  return (
    <>
      <Layout location={location} title={"Funny Zoom Background"}>
        <SEO title="Funny Zoom Background | Disney Zoom Backgrounds and Star Wars Zoom Background" />
        <SimpleGrid minChildWidth="333px" spacing={10}>
          {images.map(({ image }, key) => {
            return image.childImageSharp ? (
              <GridItem key={key} imageData={image.childImageSharp.fluid} />
            ) : null
          })}
        </SimpleGrid>
      </Layout>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allFile(filter: { name: { eq: "images" } }) {
      edges {
        node {
          childrenImagesJson {
            title
            cat_slug
            tags
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
